<template>
  <v-container style="height: 88vh" v-if="!loading" class="d-block">
    <v-row>
      <v-icon
        @click="layout = 'card'"
        :color="layout == 'card' ? 'primary' : 'grey'"
        class="mx-1"
        size="40"
        >mdi-cards</v-icon
      >
      <v-divider vertical />
      <v-icon
        @click="layout = 'table'"
        :color="layout == 'table' ? 'primary' : 'grey'"
        class="mx-1"
        size="40"
        >mdi-view-list</v-icon
      >
    </v-row>
    <v-row v-if="layout == 'table'" class="justify-center my-6">
      <v-col lg="12">
        <data-table
          @updatePage="getData({ page: $event })"
          :headers="headers"
          :data="data"
          :loader="loading"
          :meta="meta"
          :showable="
            (item) => {
              $router.push(`edu_class/${item.id}`);
            }
          "
        />
      </v-col>
    </v-row>
    <v-row v-else class="my-6">
      <v-col v-for="(item, i) in data" :key="i" lg="4">
        <edu-card :data="item" />
      </v-col>
    </v-row>
    <pagination
      v-if="layout == 'card'"
      @updatePage="getData({ page: $event })"
      :data="meta"
    />
  </v-container>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { eduClass } from "@/store/teacher/eduClass/index";
import eduCard from "./eduCard.vue";
export default {
  components: {
    eduCard,
  },
  data() {
    return {
      eduClass,
      layout: "card",
      headers: {
        id: {
          name: "id",
          keys: ["id"],
        },
        name: {
          name: "name",
          keys: ["name"],
        },
        grade: {
          name: "grade",
          keys: ["grade"],
        },
        educational_level: {
          name: "educational_level",
          keys: ["educational_level"],
        },
        educational_level: {
          name: "student_count",
          keys: ["student_count"],
        },
      },
    };
  },
  methods: {
    getData(params) {
      eduClass.get(params);
    },
  },
  computed: {
    data() {
      return eduClass.tableData.data;
    },
    meta() {
      return eduClass.tableData.meta;
    },
    loading() {
      return eduClass.getState.loading;
    },
  },
  mounted() {
    this.getData({ page: 1 });
  },
};
</script>
