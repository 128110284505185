<template>
  <div>
    <v-card @click="$router.push(`edu_class/${data.id}`)" class="card pa-1">
      <h3 class="ma-4 primary--text">{{ data.name }}</h3>
      <v-divider class="my-6"></v-divider>
      <v-row class="justify-space-around align-center my-4">
        <v-col lg="4">
          <p class="primary--text ">
            {{ $t("grade") }}
          </p>
          <p class="text-caption">{{ data.grade }}</p>
        </v-col>
        <v-col lg="4">
          <p class="primary--text">{{ $t("educational_level") }}</p>
          <p class="text-caption">{{ data.educational_level }}</p>
        </v-col>
        <v-col lg="4">
          <p class="primary--text">{{ $t("student_count") }}</p>
          <p class="text-caption">{{ data.student_count }}</p>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 20px !important;
  background-color: azure !important;
  h3,
  p,
  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  h4,
  h3 {
    font-weight: 700;
  }
  h6 {
    font-weight: 700;
  }

  div {
    text-align: center;
  }
}
</style>
